.loading{
  margin: 20px 0;
  padding: 30px 50px;
  border-radius: 4px;
  font-size: 40px
}

.ant-card-head{
  font-size: 14px !important;
  font-weight: bold !important;
  color: #1677ff !important;
}

.ant-card-head-title{
  padding: 5px !important;
}

.ant-card-head-title .description-monitor{
  font-size: 22px !important;
}

.ant-card-head-title .picture-monitor{
  font-size: 22px !important;
}

.ant-form-item-label > label{
  font-size: 22px !important;
}

.ant-card-body{
  padding: 5px 10px !important;
}

.label-monitor{
  font-size: 22px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  padding: 5px !important;
}

.ant-table table { 
  font-size: 25px; 
}

.ant-layout::before{
  content: "";
  background-image: url("../public/lidar.jpeg") !important;
  opacity: 0.8;
  background-size: cover;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}